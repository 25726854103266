import { Currency } from 'constants/currency';
import { Paginate, ResponseError } from 'modules/common/types';
import { UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

export const getAllIds = (
  selectedRow: Record<string, boolean>,
  data: Paginate<any> | undefined
) => {
  if (!data) {
    return [];
  }

  const ids = Object.keys(selectedRow)
    .map((index) => data.items[parseInt(index)].id)
    .filter((id) => id !== undefined) as string[];

  return ids;
};

export const disableMultipleAction = (
  canAction: (item: string) => void,
  selectedRow: Record<string, boolean>,
  data: Paginate<any> | undefined
) => {
  const items =
    Object.keys(selectedRow).map(
      (index) => data?.items[parseInt(index)].status
    ) || [];

  return !items.every((item) => canAction(item || ''));
};

export const setValidationError = (
  error: ResponseError,
  setError: UseFormSetError<any>
) => {
  if (error.errors?.code === 422) {
    const validationMessages = error.errors.message;
    console.log(validationMessages);
    if (validationMessages) {
      for (const key in validationMessages) {
        setError(snakeCaseToCamelCase(key) as string, {
          type: 'server',
          message: validationMessages[key],
        });
      }
    }
  }
};

export const showErrorMessage = (error: ResponseError) => {
  if (error.errors) {
    Object.values(error.errors.message).forEach((message) => {
      toast(message, {
        autoClose: false,
      });
    });
  }
};

// ex: ["HO6FI001"] -> HO6FI001
export const removeSquareBracket = (characters: string | undefined) => {
  if (!characters) {
    return '';
  }
  return characters.replace('["', '').replace('"]', '');
};

// Ex: 2021-09-22 10:34:31 -> 2021/09/22 10:34
export const formatTime = (date: string) => {
  const splittedDate = date.split(' ');
  const formattedDate = splittedDate[0].split('-').join('/');
  const formattedTime = splittedDate[1].substring(0, 5);
  const result = `${formattedDate} ${formattedTime}`;
  return result;
};

export const parseUndescoreString = (text: string) => {
  return text.replace('_', ' ');
};

export const parseMenuToUrl = (menu: string) => {
  type Url = {
    [key: string]: string;
  };
  const url: Url = {
    'asset groups': '/master-capex/asset-groups',
    catalogs: '/master-capex/catalogs',
    'outstanding budgets': '/master-capex/outstanding-budget',
    'budget plans': '/budget-plans',
    unbudgets: '/unbudgets',
    'outstanding pr po': '/outstanding-pr-po',
    'actual YTD': '/actual-ytd',
    'budget references capex': '/budget-references',
    'budget periods': '/master-capex/budget-periods',
    summary: '/summary',
    overbudgets: '/overbudgets',
    reclass: '/reclass',
    'purchase request capex': '/purchase-requests',
    'pending task': '/pending-tasks',
    'adjustment real': '/adjustment-real',
  };
  return url[menu] || '/';
};

// Ex: hello_world -> helloWorld
export const snakeCaseToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
};

export const formatMoney = (
  nominal: string | number | null | undefined,
  currency: string | null | undefined,
  defaultResult?: string | number
) => {
  const emptyResult = defaultResult || 0;
  if (!nominal || !currency) return emptyResult;

  let total;
  if (currency === Currency.Idr) {
    total = nominal.toLocaleString('id-Id', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (currency !== Currency.Idr) {
    total = nominal.toLocaleString('en-En', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    total = emptyResult;
  }

  return total;
};
