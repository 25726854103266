import { useNotificationsHelpers } from 'modules/notifications/helpers';
import Image from 'next/image';
import Link from 'next/link';
import { Col, Dropdown, Nav, Row, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

const NotificationDropdown: React.FC = () => {
  const { dataHookNotifications, dataLength, hasMore, readNotification } =
    useNotificationsHelpers();

  return (
    <Dropdown
      as={Nav.Item}
      className="d-flex pr-3 notification-admin empty"
      id="scroll-admin"
    >
      <Dropdown.Toggle as={Nav.Link}>
        <div className="position-relative padding-button">
          <Image
            src="/images/icons/ic_notification.svg"
            width={20}
            height={20}
            alt="Notification"
          />
          <span className={!!dataLength() ? 'dot' : ''}></span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu align="right">
        <Dropdown.Header>Notification</Dropdown.Header>
        {!!dataLength() && (
          <>
            <div id="scrollableDiv" className="dropdown-wrapper">
              <InfiniteScroll
                dataLength={dataLength()}
                next={dataHookNotifications.fetchNextPage}
                hasMore={hasMore()}
                style={{ overflowX: 'hidden' }}
                endMessage={<></>}
                loader={
                  <Row className="notification__row--card">
                    <Col lg={12}>
                      <div className="notification__card justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </Col>
                  </Row>
                }
                scrollableTarget="scrollableDiv"
              >
                {dataHookNotifications.data?.pages.map((page, index) => (
                  <div key={`page-${index}`}>
                    {page.items.map((item, indexItem) => (
                      <Dropdown.Item
                        key={`item-${indexItem}`}
                        onClick={() => readNotification(item)}
                      >
                        <div
                          className={`notification-item ${
                            !item.read && 'notification__card--unread'
                          }`}
                        >
                          <h5 className="text__blue notification-title">
                            {item.title}
                          </h5>
                          <span className="notification-text admin__data--text-black">
                            {item.content} <br />
                            {item.createdAt}
                          </span>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </div>
                ))}
              </InfiniteScroll>
            </div>
            <Dropdown.Header className="notification-all">
              <Link href="/notifications" passHref>
                See All Notifications
              </Link>
            </Dropdown.Header>
          </>
        )}

        {!dataHookNotifications.isLoading && !!!dataLength() && (
          <Dropdown.Item>
            <div className="notification-item">
              <span className="notification-text text-center">
                No Notifications
              </span>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
