import { useDecodeToken } from 'modules/custom/useDecodeToken';
import { useRouter } from 'next/router';

import { readNotifications } from './api';
import { Notification } from './entities';
import { useFetchNotifications } from './hook';

export const useNotificationsHelpers = () => {
  const router = useRouter();
  const [profile] = useDecodeToken();
  const dataHookNotifications = useFetchNotifications({
    userId: profile?.id,
    pageSize: 10,
  });

  const readNotification = (notification: Notification) => {
    readNotifications(notification.id).then(() => {
      if (notification.resourceType === 'budget_plan_item_group') {
        router.push(`/budget-plans/${notification.resourceId}`);
      } else if (notification.resourceType === 'unbudget') {
        router.push(`/unbudgets/${notification.resourceId}/detail`);
      } else if (notification.resourceType === 'overbudget') {
        router.push(`/overbudgets/${notification.resourceId}/detail`);
      } else if (notification.resourceType === 'reclass') {
        router.push(`/reclass/${notification.resourceId}/detail`);
      }
    });
    dataHookNotifications.refetch();
  };

  const hasMore = () => {
    const lastItem =
      dataHookNotifications.data?.pages[
        dataHookNotifications.data?.pages.length - 1
      ];
    if ((lastItem?.paging.totalPages || 0) > (lastItem?.paging.pageNumber || 0))
      return true;

    return false;
  };

  const dataLength = () => {
    const sum = dataHookNotifications.data?.pages
      .map((page) => page.items.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return sum || 0;
  };

  return {
    dataHookNotifications,
    readNotification,
    hasMore,
    dataLength,
  };
};
