import { Paginate, ResponseError } from 'modules/common/types';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { fetchNotifications, FetchNotifParams } from './api';
import type { Notification } from './entities';

export const useFetchNotifications = (
  params: FetchNotifParams
): UseInfiniteQueryResult<Paginate<Notification>, ResponseError> => {
  return useInfiniteQuery(
    ['notifications'],
    ({ pageParam }) => fetchNotifications({ ...params, pageNumber: pageParam }),
    {
      getPreviousPageParam: (firstPage) => firstPage?.paging.pageNumber - 1,
      getNextPageParam: (lastPage) => lastPage?.paging.pageNumber + 1,
      enabled: !!params.userId,
    }
  );
};
